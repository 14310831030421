<template>
    <div>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey" v-if="!showSuccess">
            <div class="wrapper wrapper--grey">
                <div class="advertise">
                    <h1>Manage Your Ad</h1>
                    <p class="advertise__auth--mobileonly">This section allows private advertisers to manage their ad.</p>
                    <div class="advertise__auth">
                        <div class="advertise__auth_wrapper">
                            <p class="advertise__auth--desktoponly">This section allows private advertisers to manage their ad.</p>
                            <div class="advertise__auth_panel">
                                <h3>Reset your Password</h3>
                                <form action="javascript:;">
                                    <div>
                                        <input type="email" id="email" v-model="email" placeholder=" Email" @keydown="clearErrorMsg()">
                                        <input type="submit" value=" "  @click="sendForgotPasswordRequest()">
                                        <div v-if="errorMessage.length > 0" class="form-error-2">{{ errorMessage }}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper wrapper--full wrapper--grey" v-if="showSuccess">
            <div class="wrapper wrapper--grey">
                <div class="advertise">
                    <div class="advertise__dealer edit-success">
                        <div class="advertise__dealer_edit-success">
                            <div class="advertise__dealer_edit-success_wrapper">
                                <div class="advertise__dealer_edit-success_panel">
                                    <div class="advertise__dealer_edit-success_centered">
                                        <div class="bg"></div>
                                        <h1>Your password was sent to your email!</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import Loading from 'vue-loading-overlay';
    import {mapMutations, mapState} from "vuex";
    export default {
        name: "AdvertiseForgotPassword",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs,
            Loading
        },
        data: function () {
            return {
                errorMessage: '',
                email: '',
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all",
                showSuccess:false
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
            // this.getAd();
        },
        mounted(){
          // this.updateBreadcrumb();
        },
        computed:{
            ...mapState([
                'isLoading'
            ]),
        },
        methods: {
            ...mapMutations([
                'setIsLoading'
            ]),
            clearErrorMsg(){
              this.errorMessage = '';
            },
            validateEmail(email) {
                const re = new RegExp("^(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
                return re.test(String(email).toLowerCase());
            },
            sendForgotPasswordRequest(){
                if (this.validateEmail(this.email)) {
                    this.setIsLoading(true);
                    return this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/forgot-password/', {
                        'email': this.email
                    })
                        .then(response => {
                            this.setIsLoading(false);
                            this.showSuccess = true;
                        })
                        .catch(error => {
                            console.error(error)
                            this.setIsLoading(false);
                        });

                }else{
                    if(this.email.length == 0){
                        this.errorMessage = 'The email can\'t be empty'
                    }else{
                        this.errorMessage = 'The email provided is invalid'
                    }
                }
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Forgot Password');
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
            }
        }
    }
</script>

<style scoped>

</style>
